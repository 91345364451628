import moment from 'moment';
import { axiosRequest } from '../../axiosRequest';

const adapterSalesLeadsResponse = ({ data, success }) => {
    if (success === false) return { error: true, data };
    const salesLeadsList = data.sales_leads.map((s) => {
        let saleLead = {
            ...s,
            created_at: s?.created_at &&
                moment(s?.created_at, 'YYYY-MM-DD').format('MM/DD/YYYY'),
        };
        return saleLead;
    });

    return salesLeadsList;
};

export const getSalesLeadsList = async () => {
    let options = {
        method: 'get',
        url: 'dashboard/sales-leads',
    };

    const response = await axiosRequest(options);

    const admins = adapterSalesLeadsResponse(response);

    return admins;
};
