import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../inputField';
import Button from '../../../button';
import NobelSelect from '../../../nobleSelect';
import {
	emailValidation,
	isValidBirthDate,
	isValidNumber,
	isValidPhoneNumber,
	isValidStateCodeAndZipCode,
	requiredValidation,
	stringMinLength,
	stringMaxLength,
	hasNoNumbersAndSpecialCharacters,
	validCompanyName,
	hasNoSpecialCharacters,
	validLinkedin,
	validUserIg,
	validMemberNumber,
} from '../../../../services/validations';
import { toast } from 'react-toastify';
import { AppContext, AuthContext } from '../../../../context';
import { getSelectOptions } from '../../../../services/api/form';

import './editMemberInfo.scss';
import CustomDatePicker from '../../../inputs/CustomDatePicker/index.jsx';
import InputDisabled from '../../../inputs/Disabled/index.jsx';
import { updateMemberById } from '../../../../services/api/members/updateMember.js';
import InputReferredBy from './inputReferredBy/index.js';
import TextArea from '../../../inputs/TextArea/index.jsx';

import {
	AsYouType,
	isValidNumber as isValidNumberLibJs,
} from 'libphonenumber-js';
import {
	deformatPhoneNumber,
	formattedPhoneNumber,
} from '../../../../helpers/formattedPhoneNumber.js';
import FormDisplayImage from '../../../formDisplayImage/index.js';
import InputPhoneNumber from '../../../inputs/PhoneNumber/index.jsx';
import { whiteColor } from '../../../../helpers/colorConstants.js';
import { PERMISSIONS } from 'helpers/constants';
import { userHasPermission } from 'helpers/hasPermission';

const toastOptions = {
	position: 'bottom-center',
	autoClose: 7000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'dark',
};

function EditMemberInfo({ onRequestClose, ...rest }) {
	const [isFormValid, setIsFormValid] = useState(false);

	const [dataLoaded, setDataLoaded] = useState(false);

	const { member, setRefetchMembers, setLoading, selects } =
		useContext(AppContext);
	const { user } = useContext(AuthContext);

	const [optionsVenues, setOptionsVenues] = useState(null);
	const [optionsTierType, setOptionsTierType] = useState(null);
	const [optionStates, setOptionStates] = useState(null);
	const [optionsGrms, setOptionsGrms] = useState(null);

	const [optionSelectedStates, setOptionSelectedStates] = useState(
		member?.state?.code,
	);
	const [optionSelectedTier, setOptionSelectedTier] = useState(
		member?.tier?.id,
	);
	const [optionSelectedPrimaryRestaurant, setOptionSelectedPrimaryRestaurant] =
		useState(member?.venue?.id);
	
	const [optionSelectedGrmReferral, setOptionSelectedGrmReferral] =
		useState(member?.grm?.id);

	const [optionSelectedVenueSale, setOptionSelectedVenueSale] = useState(
		member?.venue_sale?.id,
	);

	const [hasReferral, setHasReferral] = useState(member?.has_referral);

	const typeErrors = {
		phoneNumber: 'Phone Number',
		referredBy: 'Referred By',
		linkedInUrl: 'LinkedIn',
		tierTypeId: 'Tier Type',
		addressLine2: 'Address Line 2',
		instagramHandle: 'Instagram handle',
		firstName: 'First Name',
		lastName: 'Last Name',
		city: 'City',
		addressLine1: 'Address Line 1',
		state: 'State',
		zipCode: 'Zip Code',
		primaryVenueId: 'Primary Restaurant',
		occupation: 'Occupation',
		company: 'Company',
		birthDate: 'Birthday',
		gotoDinnerBeverage: 'Go-to dinner beverage',
		email: 'Email',
		memberNumber: 'Member Number',
	};

	const [editMemberForm, setEditMemberForm] = useState({
		firstName: {
			value: member?.first_name || '',
			valid: undefined,
			change: false,
		},
		lastName: {
			value: member?.last_name || '',
			valid: undefined,
			change: false,
		},
		birthDate: {
			value: member?.birth_date || '',
			valid: undefined,
			change: false,
		},
		phoneNumber: {
			value: member?.phone_number || '',
			valid: undefined,
			change: false,
		},
		email: { value: member?.email || '', valid: undefined, change: false },
		city: { value: member?.city || '', valid: undefined, change: false },
		state: {
			value: optionSelectedStates || '',
			valid: undefined,
			change: false,
		},
		zipCode: { value: member?.zip_code || '', valid: undefined, change: false },
		addressLine1: {
			value: member?.address_line1 || '',
			valid: undefined,
			change: false,
		},
		addressLine2: {
			value: member?.address_line2 || '',
			valid: undefined,
			change: false,
		},
		instagramHandle: {
			value: member?.instagram_handle || '',
			valid: undefined,
			change: false,
		},
		linkedInUrl: {
			value: member?.linkedin_url || '',
			valid: undefined,
			change: false,
		},
		company: { value: member?.company || '', valid: undefined, change: false },
		occupation: {
			value: member?.occupation || '',
			valid: undefined,
			change: false,
		},
		tierTypeId: {
			value: optionSelectedTier || 0,
			valid: undefined,
			change: false,
		},
		has_referral: {
			value: hasReferral || false,
			valid: undefined,
			change: false,
		},
		referredBy: {
			value: member?.referred_by || '',
			valid: undefined,
			change: false,
		},
		primaryVenueId: {
			value: optionSelectedPrimaryRestaurant || 0,
			valid: undefined,
			change: false,
		},
		venueIdSale: {
			value: optionSelectedVenueSale || 0,
			valid: undefined,
			change: false,
		},
		hearAboutUs: {
			value: member?.hear_about_us || '',
			valid: undefined,
			change: false,
		},
		gotoDinnerBeverage: {
			value: member?.go_to_dinner_beverage || '',
			valid: undefined,
			change: false,
		},
		profilePictureUrl: {
			value:
				member?.profile_picture_url ||
				'https://i.pinimg.com/736x/c0/74/9b/c0749b7cc401421662ae901ec8f9f660.jpg',
			valid: undefined,
			change: false,
		},
		memberNumber: { value: member?.member_number || '', valid: undefined, change: false },
	});

	// useEffect fetch or set values selects
	useEffect(() => {
		if (selects) {
			!optionStates && setOptionStates(selects.states);
			!optionsTierType && setOptionsTierType(selects.tiers);
			!optionsVenues && setOptionsVenues(selects.venues);
			!optionsGrms && setOptionsGrms(selects.grms);
		} else {
			(async () => {
				const selectsResponse = await getSelectOptions();
				if (!selectsResponse.error) {
					setOptionStates(selectsResponse.states);
					setOptionsTierType(selectsResponse.tiers);
					setOptionsVenues(selectsResponse.venues);
					setOptionsGrms(selectsResponse.grms);
				}
			})();
		}
	}, [
		selects,
		optionStates,
		optionsGrms,
		optionsTierType,
		optionsVenues,
	]);

	// use effect validations
	let formErrors = [];
	const addError = (typeError) => {
		formErrors = [...formErrors, [`Error in ${typeError} field.`]];
	};

	const validateForm = () => {
		formErrors = [];
		if (formErrors.length) return;
		// Required fields
		if (!editMemberForm.firstName.valid) addError(typeErrors.firstName);
		if (!editMemberForm.lastName.valid) addError(typeErrors.lastName);
		if (!editMemberForm.email.valid) addError(typeErrors.email);
		if (!editMemberForm.city.valid) addError(typeErrors.city);
		if (!editMemberForm.state.valid) addError(typeErrors.state);
		if (!editMemberForm.zipCode.valid) addError(typeErrors.zipCode);
		if (!editMemberForm.addressLine1.valid) addError(typeErrors.addressLine1);
		
		// New optional fields
		if (editMemberForm.birthDate.value && !editMemberForm.birthDate.valid)
			addError(typeErrors.birthDate);		
		if (editMemberForm.company.value && !editMemberForm.company.valid)
			addError(typeErrors.company);		
		if (editMemberForm.occupation.value && !editMemberForm.occupation.valid)
			addError(typeErrors.occupation);		
		if (editMemberForm.gotoDinnerBeverage.value && !editMemberForm.gotoDinnerBeverage.valid)
			addError(typeErrors.gotoDinnerBeverage);	
		if (editMemberForm.primaryVenueId.value && !editMemberForm.primaryVenueId.valid)
			addError(typeErrors.primaryVenueId);

		// Optional fields
		if (
			editMemberForm.instagramHandle.value &&
			!editMemberForm.instagramHandle.valid
		)
			addError(typeErrors.instagramHandle);
		if (editMemberForm.addressLine2.value && !editMemberForm.addressLine2.valid)
			addError(typeErrors.addressLine2);
		if (editMemberForm.tierTypeId.value && !editMemberForm.tierTypeId.valid)
			addError(typeErrors.tierTypeId);
		if (editMemberForm.linkedInUrl.value && !editMemberForm.linkedInUrl.valid)
			addError(typeErrors.linkedInUrl);
		if (editMemberForm.referredBy.value && !editMemberForm.referredBy.valid)
			addError(typeErrors.referredBy);
		if (editMemberForm.phoneNumber.value && !editMemberForm.phoneNumber.valid)
			addError(typeErrors.phoneNumber);
		if (editMemberForm.memberNumber.value && !editMemberForm.memberNumber.valid)
			addError(typeErrors.memberNumber);

		if (!editMemberForm.phoneNumber.value) addError(typeErrors.phoneNumber);
		if (formErrors.length) {
			return setIsFormValid(false);
		}
		setIsFormValid(true);
	};

	useEffect(() => {
		validateForm();
	}, [editMemberForm, setIsFormValid]);

	useEffect(() => {
		if (!dataLoaded && member) {
			setOptionSelectedPrimaryRestaurant(member?.venue?.id);
			setOptionSelectedTier(member?.tier.id);
			setOptionSelectedStates(member?.state?.code);
			setHasReferral(member?.has_referral);
			setEditMemberForm((prev) => ({
				...prev,
				firstName: {
					value: member.first_name,
					valid:
						requiredValidation(member.first_name) &&
						stringMaxLength(member.first_name, 100),
					change: false,
				},
				lastName: {
					value: member.last_name,
					valid:
						requiredValidation(member.last_name) &&
						stringMaxLength(member.last_name, 100),
					change: false,
				},
				birthDate: {
					value: member.birth_date || '',
					valid: isValidBirthDate(member.birth_date),
					change: false,
				},
				phoneNumber: {
					value: formattedPhoneNumber(member.phone_number) || '',
					valid: isValidPhoneNumber(member.phone_number),
					change: false,
				},
				email: {
					value: member.email || '',
					valid: emailValidation(member.email),
					change: false,
				},
				city: {
					value: member.city || '',
					valid: validateField('city', member.city),
					change: false,
				},
				state: {
					value: member.state?.code || '',
					valid: validateField('state', member.state?.code),
					change: false,
				},
				zipCode: {
					value: member.zip_code || '',
					valid: member.state?.code && validateField('zipCode', member.zip_code),
					change: false,
				},
				addressLine1: {
					value: member.address_line1 || '',
					valid: validateField('addressLine1', member.address_line1),
					change: false,
				},
				addressLine2: {
					value: member.address_line2 || '',
					valid:
						member.address_line2 &&
						validateField('addressLine2', member.address_line2),
					change: false,
				},
				instagramHandle: {
					value: member.instagram_handle || '',
					valid:
						member.instagram_handle &&
						stringMinLength(member.instagram_handle, 8),
					change: false,
				},
				company: {
					value: member.company || '',
					valid:
						stringMaxLength(member.company, 80),
					change: false,
				},
				occupation: {
					value: member.occupation || '',
					valid:
						stringMaxLength(member.occupation, 80),
					change: false,
				},
				has_referral: { value: member.has_referral, valid: undefined },
				hearAboutUs: {
					value: member.hear_about_us || '',
					valid: validateField('hearAboutUs', member.hear_about_us),
					change: false,
				},
				referredBy: {
					value: member.referred_by || '',
					valid:
						member.referred_by &&
						validateField('referredBy', member.referred_by),
					change: false,
				},
				primaryVenueId: {
					value: member?.venue?.id,
					valid: validateField('primaryVenueId', member?.venue?.id),
					change: false,
				},
				grmId: {
					value: member?.grm?.id,
					valid: validateField('grmId', member?.grm?.id),
					change: false,
				},
				tierTypeId: {
					value: member?.tier?.id,
					valid: validateField('tierTypeId', member?.tier?.id),
					change: false,
				},
				gotoDinnerBeverage: {
					value: member?.go_to_dinner_beverage,
					valid:
						stringMaxLength(member?.go_to_dinner_beverage, 100),
					change: false,
				},
				linkedInUrl: {
					value: member.linkedin_url,
					valid:
						member.linkedin_url && stringMaxLength(member?.linkedin_url, 100),
					change: false,
				},
				memberNumber: {
					value: member.member_number,
					valid:
						member.member_number &&
						validMemberNumber(member?.member_number) &&
						stringMaxLength(member?.member_number, 7),
					change: false,
				},
			}));
			setDataLoaded(true);
		}
	}, [
		member,
		dataLoaded,
		hasReferral,
		setOptionSelectedPrimaryRestaurant,
		setOptionSelectedTier,
	]);

	// useEffect created to validate only the postcode from the beginning
	useEffect(() => {
		if (editMemberForm.state.value !== '') {
			let zipCodeValue = editMemberForm.zipCode.value
				? editMemberForm.zipCode.value
				: member.zip_code;
			setEditMemberForm((prev) => ({
				...prev,
				zipCode: {
					value: zipCodeValue,
					valid: validateField('zipCode', zipCodeValue),
					change: false,
				},
			}));
		}
	}, [editMemberForm.state.value, member?.zip_code]);

	const editMemberSetValues = (e) => {
		const fieldName = e.target.name;
		let fieldValue = e.target.value;
		const regularFields = {
			addressLine1: 'addressLine1',
			addressLine2: 'addressLine2',
			occupation: 'occupation',
			hearAboutUs: 'hearAboutUs',
			goToDinnerBeverage: 'goToDinnerBeverage',
			zipCode: 'zipCode',
		};
		if (fieldName === 'instagramHandle') {
			if (fieldValue.length > 1 && !fieldValue.includes("@")) fieldValue = `@${fieldValue}`;
		}
		if (fieldName === 'phoneNumber') {
			// Check if the field is cleared
			if (fieldValue === '') {
				// Handle the case when the field is cleared
				fieldValue = '';
			} else {
				// Try to parse the input as a phone number
				const parsedNumber = new AsYouType('US').input(fieldValue);

				// Check if the parsed number is valid
				if (isValidNumberLibJs(parsedNumber, 'US')) {
					fieldValue = parsedNumber;
				}
			}
		}

		if (fieldName[regularFields]) validateField(fieldName, fieldValue);

		setEditMemberForm((prev) => ({
			...prev,
			[fieldName]: {
				value: fieldValue,
				valid:
					requiredValidation(fieldValue) &&
					validateField(fieldName, fieldValue),
				change: true,
			},
		}));
	};

	const editRefferringMember = (e) => {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		if (fieldName === 'referredBy') {
			setEditMemberForm((prev) => ({
				...prev,
				[fieldName]: {
					value: fieldValue,
					valid:
						requiredValidation(fieldValue) &&
						validateField(fieldName, fieldValue),
					change: true,
				},
			}));
			if (fieldValue.length === 0) {
				setHasReferral(false);
			} else {
				setHasReferral(true);
			}
		}
	};

	const setDataPicker = (newDate) => {
		setEditMemberForm((prevState) => ({
			...prevState,
			birthDate: {
				value: newDate,
				valid:
					requiredValidation(newDate) && validateField('birthDate', newDate),
				change: true,
			},
		}));
	};

	const setSelectedUsStatesOptions = (e) => {
		const fieldValue = e.value;
		setOptionSelectedStates(fieldValue);
		setEditMemberForm((prev) => ({
			...prev,
			state: {
				value: fieldValue,
				valid:
					requiredValidation(fieldValue) && validateField('state', fieldValue),
				change: true,
			},
		}));
	};

	const setSelectedPrimaryRestaurantOptions = (e) => {
		const fieldValue = e.value;
		setOptionSelectedPrimaryRestaurant(fieldValue);
		setEditMemberForm((prev) => ({
			...prev,
			primaryVenueId: {
				value: fieldValue,
				valid:
					requiredValidation(fieldValue) &&
					validateField('primaryVenueId', fieldValue),
				change: true,
			},
		}));
	};

	const setSelectedGrmReferralOption = (e) => {
		const fieldValue = e.value;
		setOptionSelectedGrmReferral(fieldValue);
		setEditMemberForm((prev) => ({
			...prev,
			grmId: {
				value: fieldValue,
				valid:
					requiredValidation(fieldValue) &&
					validateField('grmId', fieldValue),
				change: true,
			},
		}));
	};

	const setSelectedTierOption = (e) => {
		const tierId = e.id;
		setOptionSelectedTier(tierId);
		setEditMemberForm((prev) => ({
			...prev,
			tierTypeId: {
				value: tierId,
				valid:
					requiredValidation(tierId) &&
					validateField('tierTypeId', tierId),
				change: true,
			},
		}));
	};

	const setSelectedVenueSaleOptions = (e) => {
		const fieldValue = e.value;
		setOptionSelectedVenueSale(fieldValue);
		setEditMemberForm((prev) => ({
			...prev,
			venueIdSale: {
				value: fieldValue,
				valid: validateField('venueIdSale', fieldValue),
				change: true,
			},
		}));
	};

	const editMemberSubmit = async (e) => {
		e.preventDefault();
		validateForm();
		if (isFormValid) {
			if (!userHasPermission(user, PERMISSIONS.CAN_EDIT_MEMBERS))
				return toast.error(
					`Your role does not have permission for this action.`,
					toastOptions,
				);
			setLoading(true);
			const updatedMember = await updateMemberById(member?.id, editMemberForm);
			if (!updatedMember.error) {
				setRefetchMembers(true);
				notifyAdmin('success');
				onRequestClose();
			} else {
				notifyAdmin('error-data', updatedMember.data.response_description);
			}
			setLoading(false);
		} else {
			notifyAdmin('error');
		}
	};

	const notifyAdmin = (notificationType, data) => {
		switch (notificationType) {
			case 'success':
				toast.success(
					`${editMemberForm.firstName.value} ${editMemberForm.lastName.value} was correctly edited.`,
					toastOptions,
				);
				break;
			case 'error':
				toast.error(
					`${
						formErrors.length ? formErrors[0] : ''
					} Could not edit member user. Please correct the error and try again.`,
					toastOptions,
				);
				break;
			case 'error-data':
				toast.error(data, toastOptions);
				break;
			default:
				break;
		}
	};

	// VALIDATIONS
	const validateField = (fieldName, value) => {
		switch (fieldName) {
			case 'email':
				return (
					requiredValidation(value) &&
					emailValidation(value) &&
					stringMaxLength(value, 80)
				);
			case 'firstName':
				return (
					requiredValidation(value) &&
					hasNoNumbersAndSpecialCharacters(value) &&
					stringMaxLength(value, 100)
				);
			case 'lastName':
				return (
					requiredValidation(value) &&
					hasNoNumbersAndSpecialCharacters(value) &&
					stringMaxLength(value, 100)
				);
			case 'phoneNumber':
				return isValidPhoneNumber(deformatPhoneNumber(value));
			case 'birthDate':
				return isValidBirthDate(value);
			case 'instagramHandle':
				return validUserIg(value) && stringMinLength(value, 8);
			case 'occupation':
				return stringMaxLength(value, 80);
			case 'company':
				return (
					validCompanyName(value) &&
					stringMaxLength(value, 80)
				);
			case 'addressLine1':
				return (
					requiredValidation(value) &&
					hasNoSpecialCharacters(value) &&
					stringMaxLength(value, 200)
				);
			case 'addressLine2':
				return hasNoSpecialCharacters(value) && stringMaxLength(value, 200);
			case 'referredBy':
				return stringMinLength(value, 3 && stringMaxLength(value, 100));
			case 'city':
				return stringMaxLength(value, 80);
			case 'zipCode':
				return isValidStateCodeAndZipCode(editMemberForm.state.value, value);
			case 'linkedInUrl':
				return validLinkedin(value) && stringMaxLength(value, 100);
			case 'primaryVenueId':
				return isValidNumber(value);
			case 'venueIdSale':
				return isValidNumber(value);
			case 'tierTypeId':
				return isValidNumber(value);
			case 'grmId':
				return isValidNumber(value);
			case 'state':
				return isValidStateCodeAndZipCode(value, editMemberForm.zipCode.value);
			case 'hearAboutUs':
				return stringMaxLength(value, 255);
			case 'gotoDinnerBeverage':
				return stringMaxLength(value, 100);
			case 'memberNumber':
				return validMemberNumber(value) && stringMaxLength(value, 7);
			default:
				return undefined;
		}
	};

	return (
		<div className="EditMemberInfo" {...rest}>
			<form className="EditMemberInfo__form">
				<div className="EditMemberInfo__form__section">
						<FormDisplayImage
							url={editMemberForm?.profilePictureUrl?.value}
							disabled={true}
						/>
					<div className="section-dual">
						<InputField
							id="memberNumber"
							name="memberNumber"
							displayLabel="Member Number"
							onChange={editMemberSetValues}
							onBlur={editMemberSetValues}
							value={editMemberForm?.memberNumber.value || ''}
							inputError={
								editMemberForm?.memberNumber?.value &&
								editMemberForm?.memberNumber.valid === false
									? true
									: false
							}
						/>
					</div>
					<div className="section-dual">
						<InputField
							id="firstName"
							name="firstName"
							displayLabel="First Name*"
							onChange={editMemberSetValues}
							onBlur={editMemberSetValues}
							value={editMemberForm?.firstName.value || ''}
							inputError={
								editMemberForm?.firstName.valid === false ? true : false
							}
						/>
						<InputField
							id="lastName"
							name="lastName"
							displayLabel="Last Name*"
							onChange={editMemberSetValues}
							onBlur={editMemberSetValues}
							value={editMemberForm?.lastName.value || ''}
							inputError={
								editMemberForm?.lastName.valid === false ? true : false
							}
						/>
					</div>
					<InputField
						id="addressLine1"
						name="addressLine1"
						displayLabel="Address Line 1"
						onChange={editMemberSetValues}
						onBlur={editMemberSetValues}
						value={editMemberForm?.addressLine1?.value || ''}
						inputError={
							editMemberForm?.addressLine1.valid === false ? true : false
						}
					/>
					<InputField
						id="addressLine2"
						name="addressLine2"
						displayLabel="Address Line 2"
						onChange={editMemberSetValues}
						onBlur={editMemberSetValues}
						value={editMemberForm?.addressLine2?.value || ''}
						inputError={
							editMemberForm?.addressLine2?.value &&
							editMemberForm?.addressLine2.valid === false
								? true
								: false
						}
					/>
					<div className="section-dual">
						<InputField
							id="city"
							name="city"
							displayLabel="City*"
							onChange={editMemberSetValues}
							onBlur={editMemberSetValues}
							value={editMemberForm?.city.value || ''}
							inputError={editMemberForm?.city.valid === false ? true : false}
						/>
						{/* optionStates */}
						<div>
							<label className="inputField__label">State*</label>
							<NobelSelect
								placeholder={
									optionStates?.find(
										(state) => state.value === optionSelectedStates,
									)?.label
								}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										width: '100%',
										borderColor: state.isFocused ? '#dabc75' : '#454A4B',
										backgroundColor: '#2B3033',
										color: '#F7F7F7',
									}),
									singleValue: (baseStyles) => ({
										...baseStyles,
										color: '#cccccc',
									}),
									placeholder: (baseStyles) => ({
										...baseStyles,
										color: whiteColor,
									}),
								}}
								options={optionStates}
								onChange={(e) => setSelectedUsStatesOptions(e)}
							/>
						</div>
					</div>
					<InputField
						id="zipCode"
						name="zipCode"
						displayLabel="Zip Code*"
						onChange={editMemberSetValues}
						onBlur={editMemberSetValues}
						type="number"
						value={editMemberForm?.zipCode.value || ''}
						inputError={editMemberForm?.zipCode.valid === false ? true : false}
					/>
				</div>
				<div className="EditMemberInfo__form__section">
					<InputField
						id="email"
						name="email"
						displayLabel="Email*"
						onChange={editMemberSetValues}
						onBlur={editMemberSetValues}
						value={editMemberForm?.email.value || ''}
						inputError={editMemberForm?.email.valid === false ? true : false}
					/>
					<InputPhoneNumber
						id="phoneNumber"
						name="phoneNumber"
						displayLabel="Phone Number*"
						onChange={editMemberSetValues}
						value={editMemberForm?.phoneNumber.value || ''}
						inputError={
							editMemberForm?.phoneNumber.valid === false ? true : false
						}
					/>
					<CustomDatePicker
						id="birthDate"
						name="birthDate"
						label="Birthday"
						onChange={setDataPicker}
						value={editMemberForm?.birthDate.value || ''}
						inputError={
							editMemberForm?.birthDate?.value &&
							editMemberForm?.birthDate.valid === false
								? true
								: false
						}
					/>
					<div className="section-dual">
						<InputField
							id="instagramHandle"
							name="instagramHandle"
							displayLabel="Instagram handle"
							onChange={editMemberSetValues}
							onBlur={editMemberSetValues}
							value={editMemberForm?.instagramHandle.value || ''}
							inputError={
								editMemberForm?.instagramHandle?.value &&
								editMemberForm?.instagramHandle.valid === false
									? true
									: false
							}
						/>
						<InputField
							id="linkedInUrl"
							name="linkedInUrl"
							displayLabel="LinkedIn (URL)"
							onChange={editMemberSetValues}
							onBlur={editMemberSetValues}
							value={editMemberForm?.linkedInUrl.value || ''}
							inputError={
								editMemberForm?.linkedInUrl?.value &&
								editMemberForm?.linkedInUrl.valid === false
									? true
									: false
							}
						/>
					</div>
					<InputField
						id="company"
						name="company"
						displayLabel="Company"
						onChange={editMemberSetValues}
						onBlur={editMemberSetValues}
						value={editMemberForm?.company.value || ''}
						inputError={
							editMemberForm?.company?.value &&
							editMemberForm?.company.valid === false
								? true
								: false
						}
					/>
					<InputField
						id="occupation"
						name="occupation"
						displayLabel="Occupation"
						onChange={editMemberSetValues}
						onBlur={editMemberSetValues}
						value={editMemberForm?.occupation.value || ''}
						inputError={
							editMemberForm?.occupation?.value &&
							editMemberForm?.occupation.valid === false
								? true
								: false
						}
					/>
				</div>
				<div className="EditMemberInfo__form__section">
					<div className="section-dual">
						<div className="second-form__area-two__select">
							<label className="inputField__label">Primary Restaurant</label>
							<NobelSelect
								placeholder={
									optionsVenues?.find(
										(venue) => venue.value === optionSelectedPrimaryRestaurant,
									)?.label
								}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										width: '100%',
										borderColor: state.isFocused ? '#dabc75' : '#454A4B',
										backgroundColor: '#2B3033',
										color: '#F7F7F7',
									}),
									singleValue: (baseStyles) => ({
										...baseStyles,
										color: '#cccccc',
									}),
									placeholder: (baseStyles) => ({
										...baseStyles,
										color: whiteColor,
									}),
								}}
								options={optionsVenues}
								value={optionSelectedPrimaryRestaurant}
								onChange={(e) => setSelectedPrimaryRestaurantOptions(e)}
							/>
						</div>
						<InputReferredBy
							id="referredBy"
							name="referredBy"
							displayLabel="Referred By"
							onChange={editRefferringMember}
							onBlur={editRefferringMember}
							value={editMemberForm?.referredBy.value || ''}
						/>
					</div>
					<div className="section-dual">
						<InputField
							id="gotoDinnerBeverage"
							name="gotoDinnerBeverage"
							displayLabel="Go-to dinner beverage"
							onChange={editMemberSetValues}
							onBlur={editMemberSetValues}
							value={editMemberForm?.gotoDinnerBeverage.value || ''}
							inputError={
								editMemberForm?.gotoDinnerBeverage?.value &&
								editMemberForm?.gotoDinnerBeverage.valid === false
									? true
									: false
							}
						/>
						<div className="second-form__area-two__select">
							<label className="inputField__label">GRM Referral</label>
							<NobelSelect
								placeholder={
									optionsGrms?.find(
										(grm) => grm.value === optionSelectedGrmReferral,
									)?.label
								}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										width: '100%',
										borderColor: state.isFocused ? '#dabc75' : '#454A4B',
										backgroundColor: '#2B3033',
										color: '#F7F7F7',
									}),
									singleValue: (baseStyles) => ({
										...baseStyles,
										color: '#cccccc',
									}),
									placeholder: (baseStyles) => ({
										...baseStyles,
										color: whiteColor,
									}),
								}}
								options={optionsGrms}
								value={optionSelectedGrmReferral}
								onChange={(e) => setSelectedGrmReferralOption(e)}
							/>
						</div>
					</div>
					<div className="special-section-dual">
						<TextArea
							id="hearAboutUs"
							name="hearAboutUs"
							displayLabel="How did you hear about us?"
							onChange={editMemberSetValues}
							onBlur={editMemberSetValues}
							value={editMemberForm?.hearAboutUs.value || ''}
						/>
						{user?.role?.name === 'superadmin' ? (
							<div className="second-form__area-two__select">
								<label className="inputField__label">
									Restaurant Sale Was Made
								</label>
								<NobelSelect
									placeholder={
										optionsVenues?.find(
											(venue) => venue.value === optionSelectedVenueSale,
										)?.label
									}
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											width: '100%',
											borderColor: state.isFocused ? '#dabc75' : '#454A4B',
											backgroundColor: '#2B3033',
											color: '#F7F7F7',
										}),
										singleValue: (baseStyles) => ({
											...baseStyles,
											color: '#cccccc',
										}),
										placeholder: (baseStyles) => ({
											...baseStyles,
											color: whiteColor,
										}),
									}}
									options={optionsVenues}
									value={optionSelectedVenueSale}
									onChange={(e) => setSelectedVenueSaleOptions(e)}
								/>
							</div>
						) : (
							<InputDisabled
								id="venue_sale"
								name="venue_sale"
								displayLabel="Restaurant Sale Was Made"
								disabled={true}
								value={member?.venue_sale?.name || ''}
								oneLine={true}
							/>
						)}
					</div>
				</div>
				{/* SECTION TIER TYPE */}
				<div className="EditMemberInfo__form__section membreship">
					<div className="second-form__area-two__select">
						{/* Inverstor Members */}
						{member.is_investor === true && (
							<>
								<label className="inputField__label">Tier Type*</label>
								<NobelSelect
									placeholder={
										optionsTierType?.find(
											(option) => option.id === optionSelectedTier,
										)?.label
									}
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											width: '100%',
											borderColor: state.isFocused ? '#dabc75' : '#454A4B',
											backgroundColor: '#2B3033',
											color: '#F7F7F7',
										}),
										singleValue: (baseStyles) => ({
											...baseStyles,
											color: '#cccccc',
										}),
										placeholder: (baseStyles) => ({
											...baseStyles,
											color: whiteColor,
										}),
									}}
									options={optionsTierType}
									value={
										optionsTierType?.find(
											(option) => option.id === optionSelectedTier,
										)
									}
									onChange={(e) => setSelectedTierOption(e)}
								/>
							</>
						)}
						{/* Paid Members */}
						{member.is_investor === false &&
							member.has_active_subscription_date === true &&
							member.member_number &&
							member.subscription_amount > 0 && (
								<InputDisabled
									id="payment_status"
									name="payment_status"
									displayLabel="Tier Type*"
									disabled={true}
									value={
										member?.tier?.type
									}
								/>
							)}
						{/* Non-Paid Members (Free Members) */}
						{member.is_investor === false &&
							member.member_number &&
							member.subscription_amount === 0 &&
							member.has_active_subscription_date === true && (
								<>
									<label className="inputField__label">Tier Type*</label>
									<NobelSelect
										placeholder={
											optionsTierType?.find(
												(option) => option.id === optionSelectedTier,
											)?.label
										}
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												width: '100%',
												borderColor: state.isFocused ? '#dabc75' : '#454A4B',
												backgroundColor: '#2B3033',
												color: '#F7F7F7',
											}),
											singleValue: (baseStyles) => ({
												...baseStyles,
												color: '#cccccc',
											}),
											placeholder: (baseStyles) => ({
												...baseStyles,
												color: whiteColor,
											}),
										}}
										options={optionsTierType}
										value={
											optionsTierType?.find(
												(option) => option.id === optionSelectedTier,
											)
										}
										onChange={(e) => setSelectedTierOption(e)}
									/>
								</>
							)}
					</div>
					<div className="second-form">
						<div className="second-form__area-two">
							<InputDisabled
								id="subscription_status_label"
								name="subscription_status_label"
								displayLabel="Subscription Status"
								disabled={true}
								value={member?.subscription_status_label || ''}
							/>
							<InputDisabled
								id="amount"
								name="amount"
								displayLabel="Cost"
								disabled={true}
								value={`${
									member?.subscription_amount
										? `$ ${member?.subscription_amount}`
										: '--'
								}`}
							/>
						</div>
					</div>
				</div>
				<div className="EditMemberInfo__form__section buttons_section">
					<Button
						buttonWrapperClass="cancel"
						displayLabel="Cancel"
						color="black"
						size="full"
						onClick={onRequestClose}
					/>
					<Button
						buttonWrapperClass="save"
						displayLabel="Save"
						color="white"
						size="full"
						onClick={(e) => editMemberSubmit(e)}
					/>
				</div>
			</form>
		</div>
	);
}

export default EditMemberInfo;
