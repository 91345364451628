import React from 'react';
import cn from 'classnames';

import './textArea.scss';
import NobleIcon from '../../nobleIcon';

function TextArea({ displayLabel, id, value, inputError, disabled, ...rest }) {
	return (
		<div className="textArea">
			{displayLabel && (
				<label className="textArea__label" htmlFor={id}>
					{displayLabel}
				</label>
			)}

			<div className="textArea__inputContainer">
				<textarea
					value={value}
					className={cn([
						'textArea__input',
						{ 'textArea__input-error': inputError },
					])}
					disabled={disabled && 'disabled'}
					{...rest}>
					{value}
				</textarea>

				{disabled && (
					<div className="textArea__icon">
						<NobleIcon name="lock" />
					</div>
				)}
			</div>
		</div>
	);
}

export default TextArea;
