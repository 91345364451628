import { axiosRequest } from '../../axiosRequest';

const adapterTierOptionsResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };
	const { selects } = data;
	const { tiers } = selects;

	let tierOptions = [];
	if (tiers)
		tierOptions = tiers.map((data) => ({
			value: data.name,
			label: data.name.replace('Member', ''),
		}));

	return tierOptions;
};

export const getTierOptions = async () => {
	var options = {
		method: 'get',
		url: 'dashboard/form/all-selects',
		query: {
			values: 'tiers',
		},
	};

	const response = await axiosRequest(options);
	const tiersOptions = adapterTierOptionsResponse(response);

	return tiersOptions;
};
